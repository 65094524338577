import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import {useParams} from "react-router-dom";
import {useAccessToken} from "./AuthProvider";
import '@asyncapi/web-component/lib/asyncapi-web-component';

const ApiDocs = ({docs}) => {
  const {name} = useParams();
  const doc = docs.find(doc => doc.name === name);
  const accessToken = useAccessToken();

  if (!doc) {
    return <div>Doc not found</div>
  }

  if (doc.name.includes("async")) {
    const fetchOptions = JSON.stringify({headers: {Authorization: `Bearer ${accessToken}`}});
    const config = JSON.stringify({expand: {channels: {root: true}, messages: {root: true}, schemas: {root: true}}});
    // use the web-component because the asyncapi react component doesn't work due to webpack compilation issues
    return <asyncapi-component schemaUrl={doc.url} config={config} schemaFetchOptions={fetchOptions} cssImportPath="https://unpkg.com/@asyncapi/react-component@0.24.23/lib/styles/fiori.css">
    </asyncapi-component>
  }

  return <SwaggerUI url={doc.url} requestInterceptor={req => {
    req.headers["Authorization"] = `Bearer ${accessToken}`;
    return req;
  }} />;
};

export default ApiDocs;
