import {Routes, Route, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import ApiDocs from "./ApiDocs";
import MessagePage from "./MessagePage";
import {SiteLayout} from "@jsluna/site-layout";
import {Container} from "@jsluna/grid";
import {
  Header,
  HeaderLogo,
  HeaderActions,
  HeaderMainBar,
} from "@jsluna/header";
import {Nectar} from "@jsluna/images";
import { Select } from "@jsluna/form";
import {useAccessToken} from "./AuthProvider";

const fetchDocsStateLoading = "LOADING";
const fetchDocsStateFailed = "FAILED";
const fetchDocsStateSuccess = "SUCCESS";

const App = () => {
  const [docs, setDocs] = useState([]);
  const [fetchDocsState, setFetchDocsState] = useState(fetchDocsStateLoading);
  const accessToken = useAccessToken();

  useEffect(() => {
    setFetchDocsState(fetchDocsStateLoading);

    if (!accessToken) {
      return;
    }

    fetch(`/api/docs`, {
      headers: {
        "Authorization": `Bearer ${accessToken}`
      }
    })
      .then(res => {
        if (!res.ok) {
          throw new Error("Non-200 response from list docs request");
        }
        return res.json();
      })
      .then(data => setDocs(data.docs))
      .then(() => setFetchDocsState(fetchDocsStateSuccess))
      .catch(() => setFetchDocsState(fetchDocsStateFailed));
  }, [accessToken]);

  return (
    <SiteLayout>
      <PageHeader docs={docs} />

      <Container soft className="ln-u-push-top" id="main-content">
        {fetchDocsState === fetchDocsStateLoading && (
          <MessagePage title="Loading" description="Loading API docs..." />
        )}

        {fetchDocsState === fetchDocsStateFailed && (
          <MessagePage title="Error" description="There was a problem loading API Docs" />
        )}

        {fetchDocsState === fetchDocsStateSuccess && (
          <Routes>
            <Route index path="/" element={<MessagePage title="Nectar API Docs" description="Select an API doc in the header" /> }/>
            <Route path="/docs/:name" element={<ApiDocs docs={docs} />} />
            <Route path="*" element={<MessagePage title="404" description="Not Found" />} />
          </Routes>
        )}
      </Container>
    </SiteLayout>
  );
};

export default App;

const PageHeader = ({docs}) => {
  return (
    <Header>
      <HeaderMainBar soft style={{justifyContent: "space-between"}}>
        <HeaderLogo>
          <h3 style={{marginTop: "15px"}}>
            <Nectar aria-label="Nectar logo" style={{verticalAlign: 'middle', height: '48px'}} />
            <span style={{marginLeft: "10px", color: "#404040"}}>API Docs</span>
          </h3>
        </HeaderLogo>
        <HeaderActions>
          <DocSelector docs={docs}/>
        </HeaderActions>
      </HeaderMainBar>
    </Header>
  );
};

const DocSelector = ({docs}) => {
  const navigate = useNavigate();
  const selectDoc = (name) => {
    if (name) {
      navigate(`/docs/${name}`)
    }
  };
  return (
    <div>
      <Select
        name="selectdoc"
        placeholder="Select doc"
        options={docs.map(doc => ({label: doc.name, value: doc.name}))}
        onChange={e => selectDoc(e.target.value)}
      />
    </div>
  );
};
