import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {BrowserRouter as Router} from "react-router-dom";
import "../node_modules/@jsluna/style/css/nectar.css";
import AuthProvider from "./AuthProvider";

export const oauthBaseURL = process.env.REACT_APP_OAUTH_BASE_URL;
export const oauthClientID = process.env.REACT_APP_OAUTH_CLIENT_ID;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <App/>
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
