import React, {createContext, useContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {oauthBaseURL, oauthClientID} from "./index";

const authContext = createContext({
  accessToken: null,
});

const AuthProvider = ({children}) => {
  const [accessToken, setAccessToken] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      if (location.pathname !== "/auth") {
        window.localStorage.setItem("auth_redirect_path", location.pathname);
        oauthRedirect().then(() => console.log("redirecting to oauth2 provider"));
      } else {
        oauthGetToken(new URLSearchParams(location.search))
          .then(accessToken => {
            setAccessToken(accessToken)
            const redirectPath = window.localStorage.getItem("auth_redirect_path") || "/";
            window.localStorage.removeItem("auth_redirect_path");
            if (!redirectPath.startsWith("/auth")) {
              navigate(redirectPath);
            }
          })
          .catch(e => console.log(e));
      }
    }
  }, [accessToken, location, navigate]);

  return (
    <authContext.Provider
      value={{
        accessToken: accessToken,
      }}
    >
      {accessToken ? children : <div>Authenticating...</div>}
    </authContext.Provider>
  );
};

export const useAccessToken = () => {
  return useContext(authContext).accessToken;
};

export default AuthProvider;

const stateSessionStorageKey = (state) => {
  return `oauth:${state}`;
};

const oauthRedirect = async () => {
  const state = genRandString();
  const codeVerifier = genRandString();
  const codeChallenge = await generateCodeChallengeFromVerifier(codeVerifier);
  const redirectURI = `${window.location.protocol}//${window.location.host}/auth`;
  const scope = "docs/all";

  window.sessionStorage.setItem(stateSessionStorageKey(state), JSON.stringify({
    codeVerifier: codeVerifier,
    codeChallenge: codeChallenge,
    redirectURI: redirectURI,
    scope: scope
  }));

  const params = new URLSearchParams();
  params.set("response_type", "code");
  params.set("client_id", oauthClientID);
  params.set("redirect_uri", redirectURI);
  params.set("scope", scope);
  params.set("state", state);
  params.set("code_challenge", codeChallenge);
  params.set("code_challenge_method", "S256");

  window.location.assign(`${oauthBaseURL}/oauth2/authorize?${params.toString()}`);
};

const oauthGetToken = async (params) => {
  if (!params || params.has("error")) {
    throw new Error("Error returned from OAuth provider");
  }

  const state = params.get("state");
  const code = params.get("code");

  const savedParamsStr = window.sessionStorage.getItem(stateSessionStorageKey(state));
  if (!savedParamsStr) {
    throw new Error("Unable to find state in session storage");
  }
  const savedParams = JSON.parse(savedParamsStr);

  const postParams = new URLSearchParams();
  postParams.set("grant_type", "authorization_code");
  postParams.set("client_id", oauthClientID);
  postParams.set("redirect_uri", savedParams.redirectURI);
  postParams.set("scope", "docs/all");
  postParams.set("state", state);
  postParams.set("code_verifier", savedParams.codeVerifier);
  postParams.set("code", code);

  const res = await fetch(`${oauthBaseURL}/oauth2/token`, {
    method: 'POST',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept": "application/json"
    },
    body: postParams.toString()
  });
  if (!res.ok) {
    console.log(await res.json());
    throw new Error("Non-200 response from get token request");
  }
  const resData = await res.json();
  return resData.access_token;
};

const genRandString = (len) => {
  let arr = new Uint8Array((len || 40) / 2)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec => dec.toString(16).padStart(2, "0")).join('');
};

const sha256 = (plain) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
};

const base64URLEncode = (a) => {
  let str = "";
  let bytes = new Uint8Array(a);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};

const generateCodeChallengeFromVerifier = async (v) => {
  let hashed = await sha256(v);
  return base64URLEncode(hashed);
}
